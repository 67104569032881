<script src="../../../../../AppData/Local/Temp/Rar$DRa8036.36183/starter-kit/resources/js/src/router/index.js"></script>
<template>
    <div>
        <form-wizard
            color="#7367F0"
            :title="null"
            :subtitle="null"
            shape="square"
            finish-button-text="提交"
            back-button-text="上一步"
            next-button-text="下一步"
            class="mb-3"
            @on-complete="formSubmitted"
        >
            <tab-content v-for="(question, index) in questions" :key="index" :before-change="() => validate(index)">
                <validation-observer ref="question-observer">
                    <Question v-model="question.value" :question="question" />
                </validation-observer>
            </tab-content>
        </form-wizard>
    </div>
</template>

<script>
import Question from './Question'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import '@core/assets/fonts/feather/iconfont.css'
import survey from '@/api/survey'
import question from '@/views/survey/question.json'

export default {
    components: {
        Question,
        FormWizard,
        TabContent,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    data() {
        return {
            questions: [],
            templateID: this.$route.query.template_id,
        }
    },
    created() {
        this.init()
    },
    mounted() {},
    methods: {
        transformData() {
            const params = {}

            this.questions.forEach((question) => {
                if (question.value instanceof Array) {
                    params[question.code] = question.value.join(',')
                } else {
                    params[question.code] = question.value
                }
            })

            return {
                template: this.templateID,
                ...params,
            }
        },
        init() {
            survey.templateCategories(this.$route.query.template_id).then(async (response) => {
                this.questions = question
                this.questions[0].answers = response.data.data
                this.questions[0].answers.forEach((item) => {
                    item.value = item.id
                })
                this.questions.forEach((question) => {
                    if (question.multiple) {
                        question.value = []
                    } else {
                        question.value = ''
                    }
                })
            })
        },
        async formSubmitted() {
            try {
                const params = this.transformData()
                // this.$store.dispatch('global/isLoading', true)
                await survey.websiteCreate(params).then(() => {
                    // this.$store.dispatch('global/isLoading', false)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Form Submitted',
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    })
                })
            } catch (error) {
                //
            } finally {
                // this.$router.push({ name: 'survey' })
            }
        },
        validate(index) {
            return new Promise((resolve, reject) => {
                this.$refs['question-observer'][index].validate().then((success) => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.vue-form-wizard .wizard-tab-content {
    padding: 30px 20px;
}
</style>
